import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { WindMillLoading } from 'react-loadingg';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { getAllCategories } from '../../actions/categoryAction';

import '../../styles/home/categories.scss';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const PopularProducts = () => {
  const dispatch = useDispatch();

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState('');
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarAutoHideDuration, setSnackBarAutoHideDuration] = useState(
    3000
  );

  const { categories, areCategoriesLoading, isLoaded } = useSelector(
    (state) => state.categories
  );

  useEffect(() => {
    if (categories.length === 0 && !isLoaded && !areCategoriesLoading) {
      dispatch(getAllCategories());
    }
  }, [categories, areCategoriesLoading]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackBar(false);
  };

  const handleOldBrochure = () => {
    setSnackBarStatus('success');
    setSnackBarAutoHideDuration(3000);
    setSnackBarMessage('Brochure Coming Soon');
    setShowSnackBar(true);
  };

  const renderCategories = () => {
    return categories.map((category, index) => (
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={4}
        xl={4}
        key={index}
        className="category-grid-item"
      >
        <NavLink
          key={'home'}
          className="category-item"
          to={{
            pathname: `/categories/${category.pathname}/products`,
            state: {
              pathName: `${category.pathname}`,
              categoryName: `${category.name}`,
              categoryId: `${category.id}`,
              brochure: `${category.brochure}`,
              isOldBrochure: category.isOldBrochure
                ? category.isOldBrochure
                : false,
            },
          }}
          exact={true}
        >
          <img src={category.image} alt={category.name} />
          <div className="description-container">
            <span className="title">{category.name}</span>
            <p>{category.description}</p>
          </div>
        </NavLink>
        {category.isOldBrochure ? (
          <Button className="brochure" onClick={handleOldBrochure}>
            Check Brochure
          </Button>
        ) : (
          <a
            href={category.brochure}
            target="_blank"
            rel="noopener noreferrer"
            className="brochure"
            download
          >
            Check Brochure
          </a>
        )}
      </Grid>
    ));
  };

  return (
    <div className="categories">
      <h1>Our Catalog</h1>
      <Snackbar
        open={showSnackBar}
        autoHideDuration={snackBarAutoHideDuration}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={snackBarStatus}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
      {areCategoriesLoading ? (
        <div style={{ position: 'relative', minHeight: 400 }}>
          <WindMillLoading color="#ef7f1b" />
        </div>
      ) : null}
      <Grid container spacing={3} className="categories-grid-container">
        {renderCategories()}
      </Grid>
    </div>
  );
};
export default PopularProducts;

import React from 'react';

import Grid from '@material-ui/core/Grid';

import award from '../../assets/award.jpg';

import '../../styles/home/awards.scss';

const Awards = () => {
  return (
    <div className="awards-section">
      <h1>Awards & Recognition</h1>
      <div className="awards-container">
        <Grid container spacing={5}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="award-grid-item"
          >
            <img src={award} alt={'International Award'} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="award-grid-item"
          >
            <code className="message">
              We are pleased, honored & humbled to accept the Value Partnership
              Award from Master Builders Solutions Construction Chemicals BY
              MBCC GROUP. It has been a privilege to have been associated with
              Master Builders Solutions Construction Chemicals BY MBCC GROUP for
              the last 20 years. We looking forward to growing, exploring new
              opportunities & challenges with MASTER BUILDERS SOLUTIONS
              CONSTRUCTION CHEMICALS BY MBCC GROUP. I wholeheartedly thank
              Master Builders Solutions Construction Chemicals BY MBCC GROUP
              Team and my customers for their continued support.
            </code>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Awards;

import React from 'react';
import { withRouter } from 'react-router';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import '../../styles/home/quote.scss';

const Quote = ({ history }) => {
  return (
    <div className="quote-section">
      <Grid container spacing={3} className="container">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="info-container">
            <Button
              variant="contained"
              onClick={() => history.push('/contact')}
            >
              REQUEST A QUOTE
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(Quote);

import React, { useEffect, useState, useRef } from 'react';
import { withFirebase } from '../../utils/Firebase';
import { useLocation, withRouter } from 'react-router';
import Welcome from './Welcome';
import Categories from './Categories';
import Service from './Service';
import Quote from './Quote';
import Awards from './Awards';
import slider1 from '../../assets/slider-1.png';
import { Helmet } from 'react-helmet';

import '../../styles/home/home.scss';

const Home = () => {
  const catalogRef = useRef(null);
  const location = useLocation();

  const [showCatalog, setShowCatalog] = useState(false);

  useEffect(() => {
    if (location.search && location.search.indexOf('showCatalog') > 0) {
      setShowCatalog(true);
    }
  }, [location]);

  useEffect(() => {
    if (showCatalog) {
      catalogRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [showCatalog]);

  return (
    <div className="home-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Reva International: Construction Chemical Products in UAE</title>
        <meta
          name="description"
          content="Reva International is a global trading and distribution company with a network of offices in Africa, Asia, Gulf, and Europe. We provide Construction Chemical Products"
        />
        <meta
          name="keywords"
          content="construction, chemicals, epoxy flooring, industrial flooring, 
            cold room flooring, slip resistant flooring, flooring smooth finish, 
            epoxy grout, non-shrinkage grout, epoxy primer, epoxy resins, 
            epoxy screed, epoxy filler, bitumen paint, micro concrete, 
            flooring compound, self-smoothing, self-levelling screed, epoxy screed, 
            concrete adhesives, concrete repairing material, repair mortar, 
            waterplug, sealants"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Reva International: : Construction Chemical Products in UAE"
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="Reva International is a global trading and distribution company with a network of offices in Africa, Asia, Gulf, and Europe. We leverage our worldwide presence along with sourcing and marketing competencies to deliver what we promise. We deal in construction and chemical products from Master Builders Solutions Construction Chemicals BY MBCC GROUP. For further details, get in touch with us at 04-4214636 Approved &hellip;"
          data-react-helmet="true"
        />

        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org/",
            "@type": ["WebSite", "LocalBusiness", "Organization"],
            "description": "Reva International is a global trading and distribution company with a network of offices in Africa, Asia, Gulf, and Europe. We leverage our worldwide presence along with sourcing and marketing competencies to deliver what we promise. We deal in construction and chemical products from Master Builders Solutions Construction Chemicals BY MBCC GROUP. For further details, get in touch with us at 04-4214636",
            "name": "Reva International",
            "url": "http://www.revaintl.com/",
            "email": "info@revaintl.com",
            "telephone": "(+971) 4 4214636",
            "image": "http://revaintl.com/static/media/reva_logo-blue.png",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Bay Square,",
              "addressRegion": "Business Bay – Downtown Dubai, UAE",
              "streetAddress": "Office 407, Building 12",
              "hasMap": "https://www.google.com/search?q=reva+international&oq=&sourceid=chrome&ie=UTF-8#"
            },
            "potentialAction": {
              "@type": "SearchAction",
              "target": "http://www.revaintl.com/keyword/{search_term_string}",
              "query-input": "required name=search_term_string"
            },
            "openingHours": [
              "Monday, 8:30am–5:30pm",
              "Tuesday, 8:30am–5:30pm",
              "Wednesday, 8:30am–5:30pm",
              "Thursday, 8:30am–5:30pm",
              "Friday, Closed",
              "Saturday, 8:30am–3:30pm",
              "Sunday, 8:30am–5:30pm"
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "reviewCount": "1"
            },
            "sameAs": [
              "http://www.revaintl.com/",
              "https://www.facebook.com/RevaInternationalME/",
              "https://instagram.com/revainternationalllc?igshid=l5vmhkfbqr6z",
              "https://www.linkedin.com/company/reva-international-llc"
            ],
            "keywords": [
              "construction, chemicals, epoxy flooring, industrial flooring, 
              cold room flooring, slip resistant flooring, flooring smooth finish, 
              epoxy grout, non-shrinkage grout, epoxy primer, epoxy resins, 
              epoxy screed, epoxy filler, bitumen paint, micro concrete, 
              flooring compound, self-smoothing, self-levelling screed, epoxy screed, 
              concrete adhesives, concrete repairing material, repair mortar, 
              waterplug, sealants"
            ]
          }`}
        </script>
      </Helmet>
      <div className="carousel-container">
        <div className="slide-container">
          <img className="carosel-image" src={slider1} alt="slider1" />
          <div className="slide-info-container">
            <h1 className="heading">
              A Satisfied Customer Is The Best Business Strategy.
            </h1>
          </div>
        </div>
        <div className="overlay" />
      </div>

      <Welcome />

      <div ref={catalogRef}>
        <Categories />
      </div>

      <div className="company-profile-container">
        <div className="content">
          <span className="title">Company Profile</span>
          <a
            href="https://firebasestorage.googleapis.com/v0/b/reva-international.appspot.com/o/documents%2FReva%20International%20-%20Company%20Profile-%20New.pdf?alt=media&token=f3d30814-18e7-4d9e-b72c-0e5a01ba4972"
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            Download
          </a>
        </div>
      </div>

      <Service />
      <Quote />
      <Awards />
    </div>
  );
};

export default withRouter(withFirebase(Home));

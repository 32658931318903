import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import certificateImage from '../../assets/distributor-certificate.png';
import welcomeImage from '../../assets/welcome.jpg';
import masterBuilderLogo from '../../assets/master-builders-logo-new.jpg';

import '../../styles/home/welcome.scss';

const Welcome = () => {
  const [open, setOpen] = useState(false);

  const handleCertificateOpen = () => {
    setOpen(true);
  };

  const handleCertificateClose = () => {
    setOpen(false);
  };

  return (
    <div className="welcome-info">
      <Dialog
        open={open}
        onClose={handleCertificateClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <img
            src={certificateImage}
            className="distributor-image"
            alt="Distributor Certificate"
          />
        </DialogContent>
      </Dialog>

      <Grid container spacing={4} className="welcome-grid-container">
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="details">
          <h1> welcome to Reva International</h1>
          <p>
            <strong>Reva International</strong> is a global trading and
            distribution company with a network of offices in Africa, Asia,
            Gulf, and Europe. We leverage our worldwide presence along with
            sourcing and marketing competencies to deliver what we promise. We
            deal in Construction Chemical Products.
          </p>
          <p>
            <strong>
              For further details, get in touch with us at (+971) 4 4214636.
            </strong>
          </p>
          <Button varient="outlined" onClick={handleCertificateOpen}>
            Approved Distributor Certificate
          </Button>
          <Grid
            container
            spacing={4}
            className="secondary-text authorized-partner"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              className="grid-item"
            >
              <strong>
                WE ARE THE AUTHORIZED DISTRIBUTOR OF MASTER BUILDERS SOLUTIONS
                CONSTRUCTION CHEMICALS BY MBCC GROUP IN THE UAE
              </strong>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              className="grid-item"
              style={{ padding: 0 }}
            >
              <img src={masterBuilderLogo} alt="masterBuilders" />
            </Grid>
          </Grid>
          {/* <Button variant="contained">Read More</Button> */}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="image">
          <img
            src={welcomeImage}
            alt="Welcome to Construction Chemical Products"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Welcome;

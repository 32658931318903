import React from 'react';
import Grid from '@material-ui/core/Grid';
import BuildIcon from '@material-ui/icons/Build';
import ContactsIcon from '@material-ui/icons/Contacts';
import ExtensionIcon from '@material-ui/icons/Extension';
import FlashOnIcon from '@material-ui/icons/FlashOn';

import '../../styles/home/services.scss';

const Confidence = () => {
  return (
    <div className="services">
      <h1 className="title">Our Services</h1>
      <Grid container spacing={5} className="service-container">
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className="block">
          <div className="container">
            <ContactsIcon className="icon" />
            <div className="info">
              <h2>Technical Support 24x7</h2>
              <p>
                We offer 24x7 technical support to customers and guarantee
                maximum investment security through durability and reliable
                services.
              </p>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className="block">
          <div className="container">
            <ExtensionIcon className="icon" />
            <div className="info">
              <h2>Material Application Training & Mock Up</h2>
              <p>
                We provide application training and mock up for customers to
                visualize our products and learn their application.
              </p>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className="block">
          <div className="container">
            <BuildIcon className="icon" />
            <div className="info">
              <h2>Supply & Apply of Materials</h2>
              <p>
                Apart from supply, we provide experienced and efficient approved
                applicators to meet customers requirements.
              </p>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className="block">
          <div className="container">
            <FlashOnIcon className="icon" />
            <div className="info">
              <h2>Delivery</h2>
              <p>
                We deliver best quality products and service on a prompt and
                timely basis all over UAE.
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default Confidence;
